import React from "react";
import {
  FaChalkboardTeacher,
  FaInfoCircle,
  FaUniversity,
  FaUserGraduate,
} from "react-icons/fa";

const services = [
  {
    icon: <FaUniversity className="text-6xl text-blue-500 mb-4" />,
    title: "University Application Assistance",
    description: "Help with completing and submitting university applications.",
    details: [
      "Guidance on selecting the right universities.",
      "Assistance with application forms and essays.",
      "Submission tracking and follow-up.",
    ],
    pricing: "$500 - $1000",
  },
  {
    icon: <FaChalkboardTeacher className="text-6xl text-green-500 mb-4" />,
    title: "Educational Counseling",
    description: "Personalized advice on choosing universities and programs.",
    details: [
      "One-on-one counseling sessions.",
      "Career assessment and planning.",
      "Support with course selection.",
    ],
    pricing: "$300 - $700",
  },
  {
    icon: <FaUserGraduate className="text-6xl text-yellow-500 mb-4" />,
    title: "Visa Assistance",
    description:
      "Support with the visa application process for international students.",
    details: [
      "Preparation of required documents.",
      "Guidance on visa interviews.",
      "Application tracking and updates.",
    ],
    pricing: "$200 - $500",
  },
  {
    icon: <FaInfoCircle className="text-6xl text-red-500 mb-4" />,
    title: "Student Inquiries",
    description:
      "Providing detailed information about studying in Egypt and available programs.",
    details: [
      "Answering questions about university programs.",
      "Providing information on living in Egypt.",
      "Support with accommodation and logistics.",
    ],
    pricing: "Free",
  },
];

const OurServices = () => {
  return (
    <section id="our-services" className="py-20 text-center bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white flex flex-col justify-center items-center py-6 rounded-xl shadow-2xl p-6"
            >
              {service.icon}
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="mb-4">{service.description}</p>
              <ul className="text-left mb-4">
                {service.details.map((detail, idx) => (
                  <li key={idx} className="list-disc list-inside mb-2">
                    {detail}
                  </li>
                ))}
              </ul>
              {/* <p className="font-bold text-lg">Pricing: {service.pricing}</p> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const HowItWorks = () => {
  return (
    <section id="how-it-works" className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">How It Works</h2>
        <div className="text-left max-w-4xl mx-auto">
          <h3 className="text-2xl font-bold mb-4">Process Overview</h3>
          <p className="mb-6">
            Our services are designed to be straightforward and effective. We
            start with an initial consultation to understand your needs,
            followed by personalized assistance tailored to help you achieve
            your academic goals.
          </p>
          <h3 className="text-2xl font-bold mb-4">Timeline</h3>
          <p className="mb-6">Application processing time: 2-4 weeks</p>
          <p className="mb-6">Document verification period: 1-2 weeks</p>
          <h3 className="text-2xl font-bold mb-4">FAQs</h3>
          <p className="mb-6">
            <strong>Q:</strong> How do I get started?
            <br />
            <strong>A:</strong> You can start by contacting us through our
            website or visiting our office for an initial consultation.
          </p>
          <p className="mb-6">
            <strong>Q:</strong> What documents do I need?
            <br />
            <strong>A:</strong> Required documents vary based on the service,
            but generally include academic transcripts, identification, and
            proof of residency.
          </p>
          <p className="mb-6">
            <strong>Q:</strong> How much does it cost?
            <br />
            <strong>A:</strong> Pricing varies by service. Please refer to the
            service descriptions above for detailed pricing information.
          </p>
        </div>
      </div>
    </section>
  );
};

const App = () => {
  return (
    <div>
      <OurServices />
      <HowItWorks />
    </div>
  );
};

export default App;
