import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

const ContactUs = () => {
  return (
    <footer
      id="contact-us"
      className="bg-gray-800 text-white py-10 text-center"
    >
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-8">Contact Us</h2>

        {/* Contact Form */}
        <form className="mb-8 max-w-lg mx-auto">
          <div className="mb-4">
            <input
              type="text"
              className="w-full p-2 rounded"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              className="w-full p-2 rounded"
              placeholder="Your Email"
            />
          </div>
          <div className="mb-4">
            <textarea
              className="w-full p-2 rounded"
              placeholder="Your Message"
              rows="4"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Send Message
          </button>
        </form>

        {/* Direct Contact Information */}
        <div className="mb-8">
          <p className="mb-2">Email: info@grandtrust-ed.com</p>
          <p className="mb-2">Phone: +20 123 456 7890</p>
          <p className="mb-2">Address: 123 Main St, Cairo, Egypt</p>
        </div>

        {/* Office Location */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Office Location</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.8911883452155!2d31.235711315118013!3d30.044419981874024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840c110f4e4ff%3A0x2d2f03a86bd4e6e2!2s123%20Main%20St%2C%20Cairo%2C%20Egypt!5e0!3m2!1sen!2sus!4v1595968693416!5m2!1sen!2sus"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            className="w-full h-64 rounded"
          ></iframe>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center space-x-4 mb-8">
          <a href="https://www.instagram.com/gted__?utm_source=qr&igsh=MWQzNGFib201bnRjMg%3D%3D">
            <FaInstagram className="text-white text-2xl" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61558712325901&mibextid=JRoKGi">
            <FaFacebook className="text-white text-2xl" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61558712325901&mibextid=JRoKGi">
            <FaWhatsapp className="text-white text-2xl" />
          </a>
          {/* <a href="https://twitter.com">
            <FaTwitter className="text-white text-2xl" />
          </a> */}
          {/* <a href="https://linkedin.com">
            <FaLinkedin className="text-white text-2xl" />
          </a> */}
        </div>

        {/* Newsletter Signup */}
        <div className="mb-8 max-w-lg mx-auto">
          <h3 className="text-2xl font-semibold mb-4">
            Sign Up for Our Newsletter
          </h3>
          <form>
            <div className="mb-4">
              <input
                type="email"
                className="w-full p-2 rounded"
                placeholder="Your Email"
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Subscribe
            </button>
          </form>
        </div>

        {/* Consultation Booking */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Book a Consultation</h3>
          <a
            href="https://calendly.com/yourcalendlylink"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Schedule Now
          </a>
        </div>
      </div>
    </footer>
  );
};

export default ContactUs;
