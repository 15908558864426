import React from "react";

const partnerships = [
  {
    type: "University Partnerships",
    items: [
      "Cairo University",
      "Ain Shams University",
      "Alexandria University",
      "XYZ University",
    ],
  },
  {
    type: "Ministry Collaboration",
    description:
      "We collaborate closely with the Ministry of Higher Education to ensure that our services are aligned with the latest educational standards and policies. This collaboration helps us provide accurate and up-to-date information to our students.",
  },
  {
    type: "Accreditations",
    items: [
      "ISO 9001:2015 Certified",
      "Member of the Egyptian Education Consultants Association",
      "Accredited by the Ministry of Higher Education",
    ],
  },
];

const PartnershipsAndAffiliations = () => {
  return (
    <section
      id="partnerships-and-affiliations"
      className="py-20 text-center bg-white"
    >
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">
          Partnerships and Affiliations
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {partnerships.map((section, index) => (
            <div key={index} className="bg-gray-100 p-6 rounded-xl shadow-2xl">
              <h3 className="text-xl font-bold mb-4">{section.type}</h3>
              {section.items ? (
                <ul className="list-disc list-inside text-left">
                  {section.items.map((item, idx) => (
                    <li key={idx} className="mb-2">
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{section.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnershipsAndAffiliations;
