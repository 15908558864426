import React from "react";
import { Link } from "react-scroll";

const AboutUs = () => {
  return (
    <section id="about-us" className="py-10 bg-gray-100 text-center">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-20">
        <div className="w-full lg:w-1/3">
          <img
            src="/assets/aucImage.jpg"
            alt="About Us"
            className="mx-auto rounded-md"
          />
        </div>

        <div className="w-full lg:w-2/3 rounded-md shadow-2xl p-10">
          <h2 className="text-3xl font-semibold mb-4">About Us</h2>
          <p className="mb-6">
            Grand Trust Ed is an Egyptian company dedicated to helping students
            apply to Egyptian universities and providing all necessary
            information regarding studying in Egypt. We understand the
            challenges that students face when navigating the complex
            application process and aim to simplify it for them. Our team of
            experts is committed to providing personalized support to each
            student, ensuring they have a smooth and successful experience.
          </p>
          <p className="mb-6">
            Whether it's assistance with university applications, educational
            counseling, visa support, or answering student inquiries, we are
            here to help every step of the way. Our mission is to empower
            students to achieve their academic goals and make their dreams of
            studying in Egypt a reality.
          </p>
          <Link
            to="contact-us"
            spy={true}
            smooth={true}
            duration={500}
            className="bg-blue-500 text-white px-4 py-2 rounded inline-block cursor-pointer"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
