import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header
      className="bg-contain bg-left h-[25vh] bg-[#172540]"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/coverPhoto.png)`,
        width: "100%",
        // height: "auto",
        backgroundRepeat: "no-repeat",
      }}
    >
      <nav className="container mx-auto flex justify-between items-center relative p-4">
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/grandtrustlogo.png"}
            alt="Grand Trust Ed"
            className="h-40 invisible"
          />
        </div>
        {/* Burger Menu */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-white">
            {isOpen ? (
              <FaTimes className="h-6 w-6" />
            ) : (
              <FaBars className="h-6 w-6" />
            )}
          </button>
        </div>
        {/* Overlay */}
        {isOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>
        )}
        {/* Sidebar */}
        <div
          className={`fixed top-0 right-0 h-full bg-gray-800 z-10 w-1/2 text-white transition-transform duration-300 lg:hidden ${
            isOpen ? "transform translate-x-0" : "transform translate-x-full"
          }`}
        >
          <div className="p-4">
            <button
              onClick={toggleMenu}
              className="text-white absolute top-4 right-4"
            >
              <FaTimes className="h-6 w-6" />
            </button>
            <ul className="flex flex-col space-y-4 mt-10 items-center">
              <li>
                <Link
                  to="about-us"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="text-white cursor-pointer"
                  onClick={toggleMenu}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="our-services"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="text-white"
                  onClick={toggleMenu}
                >
                  Our Services
                </Link>
              </li>
              <li>
                <Link
                  to="customer-reviews"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="text-white"
                  onClick={toggleMenu}
                >
                  Customer Reviews
                </Link>
              </li>
              <li>
                <Link
                  to="contact-us"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="text-white"
                  onClick={toggleMenu}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Links */}
        <ul className="hidden lg:flex lg:space-x-6">
          <li>
            <Link
              to="about-us"
              spy={true}
              smooth={true}
              duration={500}
              className="text-white cursor-pointer"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="our-services"
              spy={true}
              smooth={true}
              duration={500}
              className="text-white cursor-pointer"
            >
              Our Services
            </Link>
          </li>
          <li>
            <Link
              to="customer-reviews"
              spy={true}
              smooth={true}
              duration={500}
              className="text-white cursor-pointer"
            >
              Customer Reviews
            </Link>
          </li>
          <li>
            <Link
              to="contact-us"
              spy={true}
              smooth={true}
              duration={500}
              className="text-white cursor-pointer"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
