import "./App.css";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import CustomerReviews from "./components/CustomerReviews";
import Header from "./components/Header";
import NewsAndUpdates from "./components/NewsAndUpdates";
import OurServices from "./components/OurServices";
import PartnershipsAndAffiliations from "./components/PartnershipsAndAffiliations";
import ResourcesAndGuides from "./components/ResourcesAndGuides";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <AboutUs />
        <OurServices />
        <CustomerReviews />
        <ResourcesAndGuides />
        <NewsAndUpdates />
        <PartnershipsAndAffiliations />
      </main>
      <ContactUs />
    </div>
  );
}

export default App;
